var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import Vue from "vue";
import gql from "graphql-tag";
import UpdateNameForm from "./UpdateNameForm.vue";
// import UpdateShippingForm from "./UpdateShippingForm.vue";
import UpdatePersonalForm from "./UpdatePersonalForm.vue";
import UpdatePasswordForm from "../ui/form/UpdatePasswordForm.vue";
import UpdateEmailAddressForm from "./UpdateEmailAddressForm.vue";
export default Vue.extend({
    components: {
        UpdateNameForm: UpdateNameForm,
        // UpdateShippingForm,
        UpdatePersonalForm: UpdatePersonalForm,
        UpdateEmailAddressForm: UpdateEmailAddressForm,
        UpdatePasswordForm: UpdatePasswordForm,
    },
    apollo: {
        viewerStudent: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      {\n        viewerStudent {\n          id\n          ...UpdateNameForm_viewerStudent\n          ...UpdateEmailAddressForm_viewerStudent\n          # ...UpdateShippingForm_viewerStudent\n          ...UpdatePersonalForm_viewerStudent\n        }\n      }\n      ", "\n      ", "\n      ", "\n    "], ["\n      {\n        viewerStudent {\n          id\n          ...UpdateNameForm_viewerStudent\n          ...UpdateEmailAddressForm_viewerStudent\n          # ...UpdateShippingForm_viewerStudent\n          ...UpdatePersonalForm_viewerStudent\n        }\n      }\n      ", "\n      ", "\n      ", "\n    "])), UpdateNameForm.options.fragments.viewerStudent, UpdateEmailAddressForm.options.fragments.viewerStudent, UpdatePersonalForm.options.fragments.viewerStudent),
        // ${UpdateShippingForm.options.fragments.viewerStudent}
    },
    metaInfo: function () {
        return {
            title: this.$t("Account").toString(),
        };
    },
});
var templateObject_1;
